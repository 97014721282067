<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>

            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Customer </span>
                                <v-autocomplete
                                    outlined
                                    dense
                                    v-model="customer"
                                    :items="customers"
                                    default="" item-value="cust_id" item-text="cust_name"
                                    class="ma-0 pa-0 mt-2 border-12"
                                    hide-details=true
                                    clearable
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Period From </span>
                                <v-menu ref="startDateModal" v-model="startDateModal" transition="scale-transition"
                                    offset-y min-width="auto" max-width="290px" :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="ma-0 mt-2 border-12" v-model="startDate" dense clearable outlined
                                            persistent-hint append-icon="mdi-calendar"
                                            v-bind="attrs" v-on="on" hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startDate"
                                        type="month"
                                        no-title @input="startDateModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Period To </span>
                                <v-menu ref="endDateModal"
                                v-model="endDateModal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        outlined
                                        v-model="endDate"
                                        persistent-hint
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 mt-2 border-12"
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="endDate"
                                        type="month"
                                        no-title @input="endDateModal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="mt-5 border-12" style="padding: 19px;" color="info" elevation="2" small @click="submit()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-2">
            <v-col cols="12">
                <v-card outlined class="pa-md-5">  
                    <h6 class="cyan-text text-darken-3 m-0">Letter of Complaint</h6>
                    <div id="container1" style="height: 640px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mb-2">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="data"
                                    :loading="$store.state.overlay"
                                    item-class="tr_datatable"
                                    hide-default-footer
                                    fixed-header
                                    height="400"
                                    :divider="true"
                                    :light="true"
                                    :search="searchItem"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-100">
                                                <v-spacer></v-spacer>
                                                <v-text-field outlined style="max-width: 300px;" class="border-12 mr-3 mt-2" v-model="searchItem" append-icon="mdi-magnify" dense  hide-details></v-text-field>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>
import { letterOfComplaint } from "../../../backend-api/sm/production/letter_of_complaint";

export default {
    data(){
        return{
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                    text: 'Sunrise Mill',
                    disabled: false,
                    href: '/admin/sm',
                },
                {
                    text: 'Production',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
                {
                    text: 'Letter Of Complaint',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            customer:'',
            customers:[],
            startDateModal:false,
            startDate:'',
            endDateModal:false,
            endDate:'',
            searchItem: '',
            header:[],
            headersColumn:{},
            data:[],
            showDataDetail:true,
        }
    },

    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getCustomer()
        this.$store.dispatch('setOverlay', false)
    },

    methods:{
        async getCustomer(){
            let reqBody = {}
            const respData = await letterOfComplaint.fetchCustomer('', reqBody)
            if (respData.status === 200) {
                this.customers = respData.data.data
            }
        },

        async submit(){
            let startPeriode = this.startDate.replace("-", "")
            let endPeriode = this.endDate.replace("-", "")
            let reqBody = {
                'cust_id' : this.customer,
                'start_date' : startPeriode,
                'end_date' : endPeriode
            }
            const respData = await letterOfComplaint.fetchIndex('', reqBody)
            if (respData.status === 200) {

            }  else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Failed to retrieve data',
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
            }
        },
    },
}
</script>