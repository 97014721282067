import {backendApi} from "../../backend-api-sr"

export const letterOfComplaint = (() =>{
    const fetchCustomer = ( async (subPath, reqBody) => {
        var url = '/api/v3/sm/production/loc/filter-customer'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const fetchIndex = ( async (subPath, reqBody) => {
        var url = '/api/v3/sm/production/loc'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {fetchCustomer, fetchIndex};
})()